.page-item {
  font-size: 1rem;
}
/*
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}
ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}
ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}
ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}
.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}
*/
