html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-size: 0.7em;
  font-family: HelveticaNeue-Light, AppleSDGothicNeo-Light, 'Malgun Gothic',
    '맑은 고딕', sans-serif;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #35363a;
  padding: 10px;
}
header h1 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-60%, -50%);
}
header h1 a {
  color: #fff;
  font-size: 1em;
  font-weight: bold !important;
}
.white {
  color: #fff;
}

*.icon-white {
  color: white;
}
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 25px;
  line-height: 20px;
  text-align: center;
  background-color: #eee;
  border-top: 1px solid #ccc;
  color: #999;
  font-size: 0.8em;
}

#hd_sch_wr {
  margin: 0 auto;
  width: 300px;
}
#hd_sch_wr:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: '';
}
#sch_stx {
  float: left;
  width: 220px;
  height: 45px;
  padding-left: 10px;
  border-radius: 30px 0 0 30px;
  background: #fff;
  border: 0;
  border-right: 0;
  font-size: 1.25em;
  color: #222;
}
#sch_submit {
  float: left;
  width: 60px;
  height: 47px;
  border: 0;
  background: #fff;
  color: #222;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  font-size: 16px;
}

/* 왼쪽사이드메뉴 */
.sidenav {
  height: 100%;
  width: 280px;
  position: fixed;
  z-index: 903;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 60px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.075);
}
.sidenav h1 {
  position: absolute;
  top: 4px;
  text-indent: 10px;
  font-weight: bold !important;
  font-size: 1.9em;
}
.cover {
  position: absolute;
  z-index: 900;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) !important;
  display: inline;
}

.sidenav a {
  text-decoration: none;
  color: #222;
  display: block;
  transition: 0.3s;
  font-size: 1.1em;
}

.sidenav a:hover {
  color: #222;
}

.sidenav .closebtn {
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 40px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* 왼쪽사이드메뉴 */

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* [disabled]cursor:pointer; */
}

/* 메뉴 */
ul#navi {
  width: 100%;
  text-indent: 10px;
}
ul#navi,
ul#navi ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
li.group {
  margin-bottom: 0px;
}
li.group div.title {
  height: 35px;
  line-height: 35px;
  background: #d2d2d2;
  cursor: pointer;

  font-size: 1.3em;
  color: #000;
  font-weight: bold !important;
  border-bottom: 1px solid #f4f4f4;
}
ul.sub li {
  height: 32px;
  line-height: 32px;
  background: #f4f4f4;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  text-indent: 31px;
}
ul.sub li a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #000;
}
ul.sub li:hover {
  background: #e2e2e2;
}
input:focus {
  outline: none;
}

/* 메뉴 */

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.pull-right {
  float: right !important;
  width: 45%;
  padding: 10px 0px;
}

.pull-left {
  float: left !important;
  width: 55%;
  padding: 10px 0px;
}
.right {
  float: right;
  margin-right: 20px;
}
.pull-left label {
  line-height: 35px;
}

.container {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
.container:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: '';
}
label {
  vertical-align: middle;
  top: 10px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0px 50px 0px;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #35363a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #35363a;
  border-color: #35363a;
}
.text-center {
  text-align: center;
}
/* 동영상목록 */
/* style for waterfall grid */
.wf-container {
  margin: 0 auto;
}
.wf-container:before,
.wf-container:after {
  content: '';
  display: table;
}
.wf-container:after {
  clear: both;
}
.wf-box {
  margin: 10px;
}
.wf-box img {
  display: block;
  width: 100%;
}
.wf-box .content {
  border: 1px solid #ccc;
  border-top-width: 0;
  padding: 2px 8px;
}
.wf-box-main .content h3 {
  line-height: 0.5em;
  font-size: 1.5em;
  margin-bottom: 30px;
}
.wf-box .content h3 {
  line-height: 0.5em;
}
.wf-box .content p {
  color: #900;
}
.wf-box-main .content p {
  color: #900;
}
.wf-box-main .content {
  border-top-width: 0;
  padding: 2px 15px;
}
.wf-column {
  float: left;
}
.wf-box-main .vod {
  background-color: #ededed;
  min-height: 250px;
}
.wf-box .vod {
  background-color: #ededed;
  min-height: 120px;
}
.wrap {
  position: absolute;
  top: 49px;
  bottom: 25px;
  width: 100%;
  overflow-y: scroll;
}
.main_title {
  text-align: center;
  font-size: 1.5em;
}
@media screen and (mix-width: 767px) {
  .container-full {
    width: 100%;
    margin: 59px auto;
  }
}
@media screen and (min-width: 768px) {
  .wf-container {
    width: 750px;
  }
  .container {
    width: 730px;
  }
  .container-full {
    width: 768px;
    margin: 0 auto;
  }
  .main img {
    width: 730px;
  }
}

.white_content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 1;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: auto;
  text-align: center;
  z-index: 99;
}

.white_content:target {
  opacity: 1;
  pointer-events: auto;
}
.white_content > div {
  position: absolute;
  top: 1px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.white_content > div h3 {
  color: #fff;
  font-size: 2em;
  text-indent: 20px;
  margin-top: 100px;
}
.white_content > div p {
  color: #666;
  font-size: 1.2em;
  color: #fff;
}
.white_content > div .close {
  font-size: 3.4em;
  float: right;
  margin-right: 20px;
  color: #fff;
}
.root_daum_roughmap {
  width: 100%;
}

.loader {
  display: block;
  position: fixed;
  z-index: 1031;
  top: calc(50% - (50px / 2));
  text-align: center;
}
.cards {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
.cards ul {
  list-style: none;
  margin: 0px;
  display: contents;
}
.cards li {
  margin: 6px;
  /*padding: 2px 8px;*/
}

.cards-main .vod {
  background-color: #ededed;
  min-height: 250px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards-main .vod .youtubeImage {
  width: 100%;
}
.cards-main .vod .buttonPlay {
  position: absolute;
  z-index: 1;
}
.cards-main .content h3 {
  line-height: 1.3em;
  font-size: 1.5em;
  margin-bottom: 30px;
}
.cards-main .content p {
  color: #900;
}
.cards-main .content {
  border-top-width: 0;
  padding: 2px 15px;
}
.cards .vod {
  background-color: #ededed;
  height: 120px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.cards img {
  display: block;
  width: 100%;
  min-height: 100%;
}
.cards .content {
  border: 1px solid #ccc;
  border-top-width: 0;
  padding: 2px 8px;
}
.cards .content p {
  color: #900;
}
.cards .content h3 {
  color: #35363a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vod-iframe {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
.searchFav {
  padding-top: 15px;
  list-style: none;
}
.searchFav > li {
  padding: 7px 0;
  font-size: 1.2em;
  color: #fff;
}
